import styled from '@emotion/styled';
import { TopAdsViewProps } from '../../types';
import { colors, CustomCard, ImageIcon, VideoIcon } from '@innovationdepartment/proxima-ui';
import { CreativeType } from 'types/components/creatives';
import TopAdsTilesTile from './TopAdsTiles.Tile';
import { getCreativeAsset } from './helpers';
import { PAGE_DEFAULT_SIZE } from 'types/components/fbTable';

const TilesContainer = styled.div`
  position: relative;
  margin-top: 24px;
  background-color: ${colors.white};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 16px;
  overflow: auto;
  padding: 0 16px 16px;

  & > * {
    justify-self: center;
  }
`;

const creativeTypeTag = {
  [CreativeType.PHOTO]: { label: 'Static', icon: <ImageIcon /> },
  [CreativeType.VIDEO]: { label: 'Video', icon: <VideoIcon /> },
  [CreativeType.CAROUSEL]: undefined,
};

const LOADING_TILE_COUNT = PAGE_DEFAULT_SIZE;

const TopAdsTiles = (props: TopAdsViewProps) => {
  const { ads, loading, tilesContainerRef, onTileClick, onFetchNext } = props;

  return (
    <TilesContainer ref={tilesContainerRef}>
      {ads.map((ad, index) => {
        const { type, thumbnail } = getCreativeAsset(ad);

        const showInfiniteScroll = index === ads.length - 1 && !loading;
        const tag =
          (type !== undefined ? creativeTypeTag[type] : undefined) ??
          creativeTypeTag[CreativeType.PHOTO];

        return (
          <TopAdsTilesTile
            key={ad.adId}
            ad={ad}
            tag={tag}
            thumbnail={thumbnail}
            onFetchNext={onFetchNext}
            onTileClick={onTileClick}
            shouldShowInfiniteScroll={showInfiniteScroll}
          />
        );
      })}
      {loading &&
        Array.from({ length: LOADING_TILE_COUNT }).map((_, index) => (
          <CustomCard
            label="loading"
            key={`loading-tile-${ads.length + index}`}
            loading
            size="tile2"
            bodyContent="loading"
            headerProps={{ heroUrl: 'loading', tag: creativeTypeTag[CreativeType.PHOTO] }}
          />
        ))}
    </TilesContainer>
  );
};

export default TopAdsTiles;
