import styled from '@emotion/styled';
import * as dateFns from 'date-fns';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAudiencesApi, useBillingApi } from 'api';
import { useAdAccountStore, useBrandStore } from 'stores';
import { FlavorCategory } from 'types/components/audiences';
import { InsightsInterval, InsightsMetric } from 'types/insights';
import { formatDateToIsoWithoutTime } from 'utils/formatDate';
import {
  InsightsTraditionalBenchmarks,
  InsightsNoMetaIntegration,
  InsightsSubheader,
} from './Layout';
import FacebookIntegrationModal from 'ui/Modals/FacebookIntegrationModal';
import { intervalOptions, metricOptions } from './insightsOptions';
import BuildPlanModal from 'components/Billing/Stripe/Subscriptions/BuildPlanModal/BuildPlanModal.Container';
import { useProximaSDK, useShowSpinner } from 'hooks';
import { BrandInsight, FlavorCategoryInsight } from '@innovationdepartment/proxima-sdk-axios';
import { checkFlavorCategoryValue } from '../insightsHelpers';
import { TrendsHeader } from '../SharedLayout';
import { SelectOption } from '@innovationdepartment/proxima-ui';

type QueryStringParams = {
  key: string;
  value: InsightsMetric | InsightsInterval | string;
};

const ChartSection = styled.section`
  display: inline-block;
`;

const InsightsContainer = styled.div`
  background: linear-gradient(105deg, #e9fff9 0%, #e6edfe 100%);
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InsightsInnerContainer = styled.div<{ disableScroll: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ disableScroll = false }) => disableScroll && 'overflow: hidden'};
`;

const InsightsStickyTop = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
`;

const Insights = () => {
  const [searchParams] = useSearchParams();
  const insightsApi = useProximaSDK('IntelligenceApi');

  const today = dateFns.startOfToday();
  const ninetyDaysAgo = dateFns.sub(today, { days: 90 }).toISOString();
  const endOfYesterday = dateFns.startOfDay(dateFns.endOfYesterday()).toISOString();

  const [showMetaIntegrationModal, setShowMetaIntegrationModal] = useState(false);

  const startDateFromSearchParams = dateFns.parseISO(
    searchParams?.get('startDate') || ninetyDaysAgo
  );
  const endDateFromSearchParams = dateFns.parseISO(searchParams?.get('endDate') || endOfYesterday);

  // metrics toggle for each chart
  const [traditionalBenchmarksMetric, setTraditionalBenchmarksMetric] = useState<InsightsMetric>(
    InsightsMetric.Roas
  );
  // const [performanceDifferencesMetric, setPerformanceDifferencesMetric] = useState<InsightsMetric>(
  //   InsightsMetric.Cpa
  // );

  // states for the query string params
  // const [anchorMetric, setAnchorMetric] = useState<InsightsMetric>(
  //   (searchParams.get('anchorMetric') as InsightsMetric) || InsightsMetric.Cpa
  // );
  const [interval, setInterval] = useState<InsightsInterval>(
    (searchParams.get('interval') as InsightsInterval) || InsightsInterval.Week
  );
  const [dateRange, setDateRange] = useState({
    startDate: startDateFromSearchParams,
    endDate: endDateFromSearchParams,
  });

  // states for the API calls
  const [flavorCategory, setFlavorCategory] = useState<string>(searchParams.get('category') || '');
  const [categories, setCategories] = useState<FlavorCategory[]>([]);

  const [tradBenchmarksFlavorCatInsights, setTradBenchmarksFlavorCatInsights] = useState<
    FlavorCategoryInsight[]
  >([]);
  const [tradBenchmarksBrandInsights, setTradBenchmarksBrandInsights] = useState<BrandInsight[]>(
    []
  );

  // const [perfDiffFlavorCatInsights, setPerfDiffFlavorCatInsights] = useState<
  //   FlavorCatInsightsResponse[]
  // >([]);
  // const [perfDiffBrandInsights, setPerfDiffBrandInsights] = useState<BrandInsightsResponse[]>([]);

  // states for subscription pill
  const [manageSubscriptionModalOpen, setManageSubscriptionModalOpen] = useState(false);
  const [trialEndDate, setTrialEndDate] = useState<string>('');

  const { adAccount } = useAdAccountStore();
  const { brand } = useBrandStore();

  const { getBrandFlavorCategory, getAvailableFlavorCategories } = useAudiencesApi();
  const { getBrandSubscription } = useBillingApi();

  const updateQueryStringParams = ({ key, value }: QueryStringParams) => {
    // Query string has to be updated like this because setSearchParams causes an unnecessary page re-render and seems to be a known bug: https://github.com/remix-run/react-router/discussions/9851
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, '', url.toString());
  };

  const fetchFlavorCategory = async () => {
    const response = await getBrandFlavorCategory();
    if (!response) return;

    setFlavorCategory(response.id);
  };

  const fetchSubscription = async () => {
    if (brand?.brandId) {
      const response = await getBrandSubscription(brand?.brandId);

      if (response.error) return;

      const subscriptionTrialEndDate = response.data.trialEndDate;

      setTrialEndDate(subscriptionTrialEndDate);
    }
  };

  // This is to fill in any missing values in the brand data for dates we have flavor category data for
  const fillBrandData = (
    brandInsights: BrandInsight[],
    flavorCategoryInsights: FlavorCategoryInsight[]
  ) => {
    const brandDates = new Map();

    brandInsights.forEach((brandData) => {
      brandDates.set(brandData.date, brandData);
    });

    return flavorCategoryInsights.map((flavorData) => {
      if (brandDates.has(flavorData.date)) return brandDates.get(flavorData.date);
      return { date: flavorData.date, value: 0 };
    });
  };

  const fetchTradBenchmarksData = async () => {
    // get brand insights
    if (adAccount) {
      const brandInsightsResponse = await insightsApi.getBrandInsights({
        brandId: brand.brandId,
        adAccountId: adAccount!.accountId,
        metric: traditionalBenchmarksMetric,
        interval,
        endDate: formatDateToIsoWithoutTime(dateRange.endDate),
        startDate: formatDateToIsoWithoutTime(dateRange.startDate),
      });
      const updatedBrandInsights = brandInsightsResponse.data;

      // This is how we make sure the chart displays 2 decimal points
      const roundedBrandInsights = updatedBrandInsights.map((brandInsight) => {
        let brandInsightValue = brandInsight.value || 0;

        // CTR gets multiplied by 100 because the backend value returns as a decimal instead of the percentage
        if (traditionalBenchmarksMetric === InsightsMetric.Ctr) brandInsightValue *= 100;

        return {
          ...brandInsight,
          value: Number(brandInsightValue.toFixed(2)),
        };
      });

      setTradBenchmarksBrandInsights(roundedBrandInsights);

      // get flavor category insights
      if (flavorCategory) {
        const flavorCatResponse = await insightsApi.getFlavorCategoryInsights({
          flavorCategoryId: checkFlavorCategoryValue(flavorCategory),
          metric: traditionalBenchmarksMetric,
          anchorMetric: traditionalBenchmarksMetric,
          interval,
          endDate: formatDateToIsoWithoutTime(dateRange.endDate),
          startDate: formatDateToIsoWithoutTime(dateRange.startDate),
        });
        let updatedFlavorCatInsights = flavorCatResponse.data;

        // CTR gets multiplied by 100 because the backend value returns as a decimal instead of the percentage
        if (traditionalBenchmarksMetric === InsightsMetric.Ctr)
          updatedFlavorCatInsights = flavorCatResponse.data.map((insight) => ({
            date: insight.date,
            rankings: insight.rankings?.map((ranking) => ({
              name: ranking.name,
              value: ranking.value! * 100,
            })),
          }));

        const roundedFlavorCat = updatedFlavorCatInsights.map((flavorCat) => ({
          ...flavorCat,
          rankings: flavorCat?.rankings?.map((ranking) => ({
            ...ranking,
            value: Number(ranking?.value?.toFixed(2)),
          })),
        }));

        setTradBenchmarksFlavorCatInsights(roundedFlavorCat);

        if (roundedBrandInsights.length !== updatedFlavorCatInsights.length) {
          const filledTradBenchmarksBrandData = fillBrandData(
            roundedBrandInsights,
            updatedFlavorCatInsights
          );

          setTradBenchmarksBrandInsights(filledTradBenchmarksBrandData!);
        }
      }
    }
  };

  // const fetchPerfDiffData = async () => {
  //   // get brand insights
  //   if (adAccount) {
  //     const brandInsightsResponse = await getBrandInsights({
  //       brandId: brand.brandId,
  //       adAccountId: adAccount!.accountId,
  //       metric: performanceDifferencesMetric,
  //       interval,
  //       endDate: formatDateToIsoWithoutTime(dateRange.endDate),
  //       startDate: formatDateToIsoWithoutTime(dateRange.startDate),
  //     });

  //     setPerfDiffBrandInsights(brandInsightsResponse.data);

  //     // get flavor category insights
  //     if (flavorCategory) {
  //       const flavorCatResponse = await getFlavorCatInsights({
  //         flavorCategoryId: flavorCategory!.id,
  //         metric: performanceDifferencesMetric,
  //         anchorMetric,
  //         interval,
  //         endDate: formatDateToIsoWithoutTime(dateRange.endDate),
  //         startDate: formatDateToIsoWithoutTime(dateRange.startDate),
  //       });

  //       setPerfDiffFlavorCatInsights(flavorCatResponse.data);
  //     }
  //   }
  // };

  // const onAnchorMetricChanged = (newAnchorMetric: InsightsMetric) => {
  //   setAnchorMetric(newAnchorMetric);
  //   updateQueryStringParams({ key: 'anchorMetric', value: newAnchorMetric });
  // };

  const onIntervalChanged = (newInterval: InsightsInterval) => {
    setInterval(newInterval);
    updateQueryStringParams({ key: 'interval', value: newInterval });
  };

  const onDateRangeSelect = (newDateRange: any) => {
    setDateRange(newDateRange);
    updateQueryStringParams({
      key: 'startDate',
      value: formatDateToIsoWithoutTime(newDateRange.startDate),
    });
    updateQueryStringParams({
      key: 'endDate',
      value: formatDateToIsoWithoutTime(newDateRange.endDate),
    });
  };

  const onCategorySelect = (newCategory: string) => {
    setFlavorCategory(newCategory);
    updateQueryStringParams({ key: 'category', value: newCategory });
  };

  const traditionalBenchmarksHighchartOptions = {
    brandInsights: tradBenchmarksBrandInsights,
    flavorCatInsights: tradBenchmarksFlavorCatInsights,
  };
  // const performanceDifferencesHighchartOptions = {
  //   brandInsights: perfDiffBrandInsights,
  //   flavorCatInsights: perfDiffFlavorCatInsights,
  // };

  // const anchorOptionLabel = metricOptions.find((option) => option.value === anchorMetric)?.label!;

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await getAvailableFlavorCategories();

      setCategories(response.data as FlavorCategory[]);
    };

    fetchCategories();
    fetchFlavorCategory();
    fetchSubscription();
  }, []);

  useEffect(() => {
    fetchTradBenchmarksData();
  }, [flavorCategory, traditionalBenchmarksMetric, interval, dateRange]);

  // useEffect(() => {
  //   fetchPerfDiffData();
  // }, [flavorCategory, performanceDifferencesMetric, anchorMetric, interval, dateRange]);

  const intervalLabel = intervalOptions.find((option) => option.value === interval)?.label!;

  const categoryOptions: SelectOption[] = [
    {
      label: 'All Categories',
      value: 'all',
    },
    ...categories.map(
      (cat) =>
        ({
          label: cat.name,
          value: cat.id,
        } as SelectOption)
    ),
  ];

  useShowSpinner({ show: insightsApi.loading });

  return (
    <InsightsContainer>
      {!adAccount && (
        <InsightsNoMetaIntegration
          onConnectMetaAccountClicked={() => setShowMetaIntegrationModal(true)}
        />
      )}
      <FacebookIntegrationModal
        onClose={() => setShowMetaIntegrationModal(false)}
        open={showMetaIntegrationModal}
      />
      <InsightsStickyTop>
        <TrendsHeader
          title="Benchmarks | Meta"
          trialEndDate={trialEndDate}
          onOpenManageSubscriptionModal={() => setManageSubscriptionModalOpen(true)}
        />
        <InsightsSubheader
          category={flavorCategory || ''}
          categoryOptions={categoryOptions}
          dateRange={dateRange}
          intervalOptions={intervalOptions}
          interval={intervalLabel}
          onCategorySelect={onCategorySelect}
          onDateRangeSelect={onDateRangeSelect}
          onIntervalChanged={onIntervalChanged}
        />
      </InsightsStickyTop>
      <InsightsInnerContainer disableScroll={!adAccount}>
        <ChartSection>
          <InsightsTraditionalBenchmarks
            currentMetric={traditionalBenchmarksMetric}
            metricsOptions={metricOptions}
            chartOptions={traditionalBenchmarksHighchartOptions}
            onMetricChanged={setTraditionalBenchmarksMetric}
          />
        </ChartSection>
        {/* TODO (Milecia): leaving this commented out because we'll likely add this back at some point. */}
        {/* <ChartSection>
          <InsightsPerformanceDifferences
            currentMetric={performanceDifferencesMetric}
            anchorMetric={anchorOptionLabel}
            metricsOptions={metricOptions}
            chartOptions={performanceDifferencesHighchartOptions}
            onMetricChanged={setPerformanceDifferencesMetric}
          />
        </ChartSection> */}
      </InsightsInnerContainer>
      <BuildPlanModal
        brandId={brand.brandId}
        open={manageSubscriptionModalOpen}
        onClose={() => setManageSubscriptionModalOpen(false)}
      />
    </InsightsContainer>
  );
};

export default Insights;
