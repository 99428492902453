import styled from '@emotion/styled';
import * as dateFns from 'date-fns';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useProximaSDK, useShowSpinner } from 'hooks';
import { useAdAccountStore, useBrandStore } from 'stores';
import { formatDateToIsoWithoutTime } from 'utils/formatDate';
import { BrandInsight, FlavorCategoryInsight } from '@innovationdepartment/proxima-sdk-axios';
import { InsightsNoMetaIntegration } from '../SharedLayout';
import TrendsView from './Trends.View';
import {
  checkFlavorCategoryValue,
  fillBrandData,
  intervalOptions,
  updateQueryStringParams,
} from '../insightsHelpers';
import {
  HeatmapViewIntensity,
  InsightsColumnData,
  InsightsInterval,
  InsightsMetric,
} from 'types/insights';
import { useBillingApi } from 'api';
import BuildPlanModal from 'components/Billing/Stripe/Subscriptions/BuildPlanModal/BuildPlanModal.Container';
import FacebookIntegrationModal from 'ui/Modals/FacebookIntegrationModal';

const TrendsContainer = styled.div<{ disableScroll: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ disableScroll = false }) => disableScroll && 'overflow: hidden'};
`;

const Trends = () => {
  const [searchParams] = useSearchParams();
  const insightsApi = useProximaSDK('IntelligenceApi');
  const { getBrandSubscription } = useBillingApi();

  const { adAccount } = useAdAccountStore();
  const { brand } = useBrandStore();

  const today = dateFns.startOfToday();
  const ninetyDaysAgo = dateFns.sub(today, { days: 90 }).toISOString();
  const endOfYesterday = dateFns.startOfDay(dateFns.endOfYesterday()).toISOString();

  const startDateFromSearchParams = dateFns.parseISO(
    searchParams?.get('startDate') || ninetyDaysAgo
  );
  const endDateFromSearchParams = dateFns.parseISO(searchParams?.get('endDate') || endOfYesterday);

  const [interval, setInterval] = useState<InsightsInterval>(
    (searchParams.get('interval') as InsightsInterval) || InsightsInterval.Week
  );
  const [flavorCategory, setFlavorCategory] = useState<string>(
    searchParams.get('flavorCategory') || 'all'
  );
  const [dateRange, setDateRange] = useState({
    startDate: startDateFromSearchParams,
    endDate: endDateFromSearchParams,
  });
  const [heatmapViewIntesity, setHeatmapViewIntesity] = useState<HeatmapViewIntensity>(
    (searchParams.get('heatmapViewIntesity') as HeatmapViewIntensity) || 'bright'
  );
  const [showMetaIntegrationModal, setShowMetaIntegrationModal] = useState(false);

  // state for the data from all the API calls
  const [insightsColumnData, setInsightsColumnData] = useState<InsightsColumnData[]>([]);

  // states for subscription pill
  const [manageSubscriptionModalOpen, setManageSubscriptionModalOpen] = useState(false);
  const [trialEndDate, setTrialEndDate] = useState<string>('');

  const fetchSubscription = async () => {
    if (brand?.brandId) {
      const response = await getBrandSubscription(brand?.brandId);

      if (response.error) return;

      const subscriptionTrialEndDate = response.data.trialEndDate;

      setTrialEndDate(subscriptionTrialEndDate);
    }
  };

  const fetchFlavorCategoryByMetric = async ({
    flavorCategoryId,
    metric,
  }: {
    flavorCategoryId: string;
    metric: InsightsMetric;
  }) => {
    // metric and anchor metric are the same
    const flavorCatResponse = await insightsApi.getFlavorCategoryInsights({
      flavorCategoryId: checkFlavorCategoryValue(flavorCategoryId),
      metric,
      anchorMetric: metric,
      interval,
      endDate: formatDateToIsoWithoutTime(dateRange.endDate),
      startDate: formatDateToIsoWithoutTime(dateRange.startDate),
    });

    const flavorCatValues = flavorCatResponse.data as FlavorCategoryInsight[];

    const orderedFlavorCatValues = [...flavorCatValues].reverse();

    return orderedFlavorCatValues;
  };

  const fetchBrandInsights = async () => {
    // get brand cpa insights
    const brandCpaResponse = await insightsApi.getBrandInsights({
      brandId: brand.brandId,
      adAccountId: adAccount!.accountId,
      metric: InsightsMetric.Cpa,
      interval,
      endDate: formatDateToIsoWithoutTime(dateRange.endDate),
      startDate: formatDateToIsoWithoutTime(dateRange.startDate),
    });

    // get brand roas insights
    const brandRoasResponse = await insightsApi.getBrandInsights({
      brandId: brand.brandId,
      adAccountId: adAccount!.accountId,
      metric: InsightsMetric.Roas,
      interval,
      endDate: formatDateToIsoWithoutTime(dateRange.endDate),
      startDate: formatDateToIsoWithoutTime(dateRange.startDate),
    });

    const brandCpaValues = brandCpaResponse.data;

    const brandRoasValues = brandRoasResponse.data;

    const orderedBrandCpa = [...brandCpaValues].reverse();

    const orderedBrandRoas = [...brandRoasValues].reverse();

    return { orderedBrandCpa, orderedBrandRoas };
  };

  // check if the brand insights data has the same length as the flavor insights and fill any values for missing dates
  const fillBrandInsights = (
    brandInsights: BrandInsight[],
    flavorCategoryInsights: FlavorCategoryInsight[]
  ): BrandInsight[] => {
    if (brandInsights.length !== flavorCategoryInsights.length) {
      const filledBrandData = fillBrandData(brandInsights, flavorCategoryInsights);
      return filledBrandData!;
    }
    return brandInsights;
  };

  // sometimes the flavor category data won't have values for the average ranking, so we handle that here
  const fillAverageRanking = (
    flavorCategoryData: FlavorCategoryInsight,
    isCtr: boolean = false
  ) => {
    if (flavorCategoryData.rankings && flavorCategoryData.rankings.length > 1) {
      if (isCtr) return Number((flavorCategoryData.rankings[1].value! * 100).toFixed(2));

      return Number(flavorCategoryData.rankings[1].value!.toFixed(2));
    }
    return 0;
  };

  const calculatePercentChange = (prevValue: number = 0, curValue: number = 0) => {
    // do this because we can't divide by zero
    if (prevValue === 0) return 0;

    const difference = curValue - prevValue;
    const percentChange = (difference / prevValue) * 100;

    return Number(percentChange.toFixed(2));
  };

  // the replace is here because of JS Date math; using a '-' returns the previous date, using a '/' returns the passed in date
  const formatDate = (date: string) => new Date(date.replace('-', '/')).toString();

  const getAllInsights = async () => {
    if (!adAccount) return;

    const [
      brandInsights,
      flavorCategoryCpm,
      flavorCategoryCtr,
      flavorCategoryCpa,
      flavorCategoryCpc,
      flavorCategoryRoas,
    ] = await Promise.all([
      fetchBrandInsights(),
      fetchFlavorCategoryByMetric({
        flavorCategoryId: flavorCategory,
        metric: InsightsMetric.Cpm,
      }),
      fetchFlavorCategoryByMetric({
        flavorCategoryId: flavorCategory,
        metric: InsightsMetric.Ctr,
      }),
      fetchFlavorCategoryByMetric({
        flavorCategoryId: flavorCategory,
        metric: InsightsMetric.Cpa,
      }),
      fetchFlavorCategoryByMetric({
        flavorCategoryId: flavorCategory,
        metric: InsightsMetric.Cpc,
      }),
      fetchFlavorCategoryByMetric({
        flavorCategoryId: flavorCategory,
        metric: InsightsMetric.Roas,
      }),
    ]);

    const brandCpa = fillBrandInsights(brandInsights.orderedBrandCpa, flavorCategoryCpa);
    const brandRoas = fillBrandInsights(brandInsights.orderedBrandRoas, flavorCategoryRoas);

    const brandCpaChange = brandCpa.map((brandCpaRow, ind, arr) => {
      if (ind + 1 > arr.length - 1) return 0;

      const curValue = brandCpaRow.value;
      const prevValue = arr[ind + 1].value;

      const percentChange = calculatePercentChange(prevValue, curValue);

      return percentChange;
    });

    const brandRoasChange = brandRoas.map((brandRoasRow, ind, arr) => {
      if (ind + 1 > arr.length - 1) return 0;

      const curValue = brandRoasRow.value;
      const prevValue = arr[ind + 1].value;

      const percentChange = calculatePercentChange(prevValue, curValue);

      return percentChange;
    });

    const flavorCategoryCpaChange = flavorCategoryCpa.map((flavorCpa, ind, arr) => {
      if (ind + 1 > arr.length - 1) return 0;

      const curValue = fillAverageRanking(flavorCpa);
      const prevValue = fillAverageRanking(arr[ind + 1]);

      const percentChange = calculatePercentChange(prevValue, curValue);

      return percentChange;
    });

    const flavorCategoryRoasChange = flavorCategoryRoas.map((flavorRoas, ind, arr) => {
      if (ind + 1 > arr.length - 1) return 0;

      const curValue = fillAverageRanking(flavorRoas);
      const prevValue = fillAverageRanking(arr[ind + 1]);

      const percentChange = calculatePercentChange(prevValue, curValue);

      return percentChange;
    });

    const completeInsightsColumnData = flavorCategoryCpa.map((flavorCpa, ind) => ({
      date: formatDate(flavorCpa.date!),
      cpm: fillAverageRanking(flavorCategoryCpm[ind]),
      ctr: fillAverageRanking(flavorCategoryCtr[ind], true),
      cpa: fillAverageRanking(flavorCpa),
      roas: fillAverageRanking(flavorCategoryRoas[ind]),
      cpc: fillAverageRanking(flavorCategoryCpc[ind]),
      cpaChange: {
        flavorCategoryCpaChange: flavorCategoryCpaChange[ind],
        brandCpaChange: brandCpaChange[ind],
      },
      roasChange: {
        flavorCategoryRoasChange: flavorCategoryRoasChange[ind],
        brandRoasChange: brandRoasChange[ind],
      },
    }));

    setInsightsColumnData(completeInsightsColumnData);
  };

  const onHeatmapViewIntesityChanged = (newHeatmapViewIntesity: HeatmapViewIntensity) => {
    setHeatmapViewIntesity(newHeatmapViewIntesity);
    updateQueryStringParams({ key: 'heatmapViewIntensity', value: newHeatmapViewIntesity });
  };

  const onFlavorCategoryChanged = (newFlavorCategory: string) => {
    setFlavorCategory(newFlavorCategory);
    updateQueryStringParams({ key: 'flavorCategory', value: newFlavorCategory });
  };

  const onIntervalChanged = (newInterval: InsightsInterval) => {
    setInterval(newInterval);
    updateQueryStringParams({ key: 'interval', value: newInterval });
  };

  const onDateRangeSelect = (newDateRange: any) => {
    setDateRange(newDateRange);
    updateQueryStringParams({
      key: 'startDate',
      value: formatDateToIsoWithoutTime(newDateRange.startDate),
    });
    updateQueryStringParams({
      key: 'endDate',
      value: formatDateToIsoWithoutTime(newDateRange.endDate),
    });
  };

  useEffect(() => {
    getAllInsights();
  }, [flavorCategory, interval, dateRange]);

  useEffect(() => {
    fetchSubscription();
  }, []);

  useShowSpinner({ show: insightsApi.loading });

  // this is for the first header column in the table
  const intervalLabel = intervalOptions.find((option) => option.value === interval)!.label;

  return (
    <TrendsContainer disableScroll={!adAccount}>
      {!adAccount && (
        <InsightsNoMetaIntegration
          onConnectMetaAccountClicked={() => setShowMetaIntegrationModal(true)}
          insightsType="trends"
        />
      )}
      <FacebookIntegrationModal
        onClose={() => setShowMetaIntegrationModal(false)}
        open={showMetaIntegrationModal}
      />
      <TrendsView
        dateRange={dateRange}
        flavorCategory={flavorCategory}
        heatmapViewIntesity={heatmapViewIntesity}
        insightsColumnData={insightsColumnData}
        interval={interval}
        trialEndDate={trialEndDate}
        intervalLabel={intervalLabel}
        intervalOptions={intervalOptions}
        onDateRangeSelect={onDateRangeSelect}
        onFlavorCategoryChanged={onFlavorCategoryChanged}
        onHeatmapViewIntesityChanged={onHeatmapViewIntesityChanged}
        onIntervalChanged={onIntervalChanged}
        onOpenManageSubscriptionModal={() => setManageSubscriptionModalOpen(true)}
      />
      <BuildPlanModal
        brandId={brand.brandId}
        open={manageSubscriptionModalOpen}
        onClose={() => setManageSubscriptionModalOpen(false)}
      />
    </TrendsContainer>
  );
};

export default Trends;
