import styled from '@emotion/styled';
import { Button, MetaWhite, Text } from '@innovationdepartment/proxima-ui';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  gap: 8px;
  align-self: stretch;
  background: linear-gradient(90deg, rgba(201, 250, 236, 0.9), rgba(203, 219, 254, 0.9));
  backdrop-filter: blur(5px);
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
  top: 69px;
  z-index: 4;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 630px;
  text-align: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const SmallerMetaIcon = styled(MetaWhite)`
  width: 16px;
  height: 16px;
`;

type InsightsType = 'chart' | 'trends';

type InsightsNoMetaIntegrationProps = {
  onConnectMetaAccountClicked: () => void;
  insightsType: InsightsType;
};

const InsightsNoMetaIntegration = (props: InsightsNoMetaIntegrationProps) => {
  const { onConnectMetaAccountClicked, insightsType } = props;

  const explanationCopy =
    insightsType === 'chart'
      ? {
          subheader:
            'Compare your performance to similar brands with tactical benchmarks. This data can help you identify areas of improvement and prioritize the work that will have the biggest impact for your business.',
          learnMore: 'benchmarks',
        }
      : {
          subheader:
            "Connect your ad account to see how your brand's performance compares to specific cohorts or the entire eCommerce industry.",
          learnMore: 'performance trends',
        };

  return (
    <Container>
      <ContentContainer>
        <InnerContainer>
          <Text variant="h2">Want see how your brand stacks up?</Text>
          <Text variant="h6" isTermina={false}>
            {explanationCopy.subheader}
          </Text>
        </InnerContainer>
        <InnerContainer>
          <Button
            onClick={onConnectMetaAccountClicked}
            size="medium"
            startIcon={<SmallerMetaIcon />}
            label={<Text variant="body2Semibold">Connect Meta</Text>}
          />
        </InnerContainer>
      </ContentContainer>
    </Container>
  );
};

export default InsightsNoMetaIntegration;
