import styled from '@emotion/styled';
import { TrendsSubheader } from './Layout';
import { TrendsTable } from './Table';
import { TrendsHeader } from '../SharedLayout';
import {
  HeatmapViewIntensity,
  InsightsColumnData,
  InsightsInterval,
  IntervalOption,
} from 'types/insights';
import { PropsOf } from '@emotion/react';
import { DatePicker } from '@innovationdepartment/proxima-ui';

const TrendsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: white;
  flex-grow: 100;
`;

const TrendsStickyTop = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
`;

const flavorCategoryOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Beauty',
    value: 'BEAU',
  },
  {
    label: 'Fashion & Accessories',
    value: 'FASH',
  },
  {
    label: 'Food & Drink',
    value: 'FOOD',
  },
  {
    label: 'Health',
    value: 'HEAL',
  },
  {
    label: 'Home',
    value: 'HOME',
  },
];

const heatmapViewIntesityOptions: { label: string; value: HeatmapViewIntensity }[] = [
  {
    label: 'Bright Heatmap',
    value: 'bright',
  },
  {
    label: 'Light Heatmap',
    value: 'light',
  },
];

type TrendsViewProps = {
  trialEndDate: string;
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  interval: InsightsInterval;
  insightsColumnData: InsightsColumnData[];
  intervalLabel: string;
  flavorCategory: string;
  heatmapViewIntesity: HeatmapViewIntensity;
  intervalOptions: IntervalOption[];
  onDateRangeSelect: PropsOf<typeof DatePicker>['onDateRangeSelect'];
  onIntervalChanged: (interval: InsightsInterval) => void;
  onFlavorCategoryChanged: (flavorCategory: string) => void;
  onHeatmapViewIntesityChanged: (view: HeatmapViewIntensity) => void;
  onOpenManageSubscriptionModal: () => void;
};

const TrendsView = (props: TrendsViewProps) => {
  const {
    trialEndDate,
    dateRange,
    interval,
    intervalLabel,
    intervalOptions,
    insightsColumnData,
    flavorCategory,
    heatmapViewIntesity,
    onDateRangeSelect,
    onFlavorCategoryChanged,
    onHeatmapViewIntesityChanged,
    onIntervalChanged,
    onOpenManageSubscriptionModal,
  } = props;

  return (
    <>
      <TrendsStickyTop>
        <TrendsHeader
          title="Performance Trends"
          trialEndDate={trialEndDate}
          onOpenManageSubscriptionModal={onOpenManageSubscriptionModal}
        />
        <TrendsSubheader
          dateRange={dateRange}
          interval={interval}
          intervalOptions={intervalOptions}
          onDateRangeSelect={onDateRangeSelect}
          onIntervalChanged={onIntervalChanged}
        />
      </TrendsStickyTop>
      <TrendsInnerContainer>
        <TrendsTable
          insightsData={insightsColumnData}
          intervalLabel={intervalLabel}
          flavorCategory={flavorCategory}
          heatmapViewIntesity={heatmapViewIntesity}
          flavorCategoryOptions={flavorCategoryOptions}
          heatmapViewIntesityOptions={heatmapViewIntesityOptions}
          onFlavorCategoryChanged={onFlavorCategoryChanged}
          onHeatmapViewIntesityChanged={onHeatmapViewIntesityChanged}
        />
      </TrendsInnerContainer>
    </>
  );
};

export default TrendsView;
