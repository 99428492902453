import { PropsOf } from '@emotion/react';
import styled from '@emotion/styled';
import {
  DatePicker,
  SelectInput,
  SelectOption,
  Text,
  Union,
  colors,
} from '@innovationdepartment/proxima-ui';
import { IntervalOption } from 'types/components/insights';
import { InsightsInterval } from 'types/insights';

const SubheaderContainer = styled.div`
  background-color: ${colors.neutral5};
  border-bottom: 1px solid ${colors.neutral20};
  display: flex;
  flex-direction: row;
  height: 64px;
  padding: 0px 32px;
  justify-content: space-between;
  align-items: center;
`;

const SubheaderDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

// const SelectInputWrapper = styled.div`
//   div {
//     border-radius: 4px;
//     min-width: 124px;
//     background-color: ${colors.neutral80};
//   }
// `;

const IconLabelContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

const SelectorContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
`;

const IntervalSelectInputWrapper = styled.div`
  div {
    border-radius: 4px;

    > fieldset {
      border: 2px solid ${colors.neutral20} !important;
    }
  }
`;

// const TooltipContext = () => (
//   <div
//     style={{
//       display: 'flex',
//       flexDirection: 'column',
//       gap: '8px',
//       padding: '12px 7px',
//       width: '176px',
//     }}
//   >
//     <Text variant="body2Semibold">About Anchor</Text>
//     <Text variant="body3">
//       Cohorts of companies are selected by how they perform in your chosen Anchor metric.
//     </Text>
//     <Text variant="body3">
//       Select an anchor to tie each benchmark below to a fixed peer group ranked by their performance
//       in the Anchor metric.
//     </Text>
//   </div>
// );

type InsightsHeaderProps = {
  category: string;
  categoryOptions: SelectOption[];
  dateRange: {
    startDate: Date;
    endDate: Date;
  };
  interval: string;
  intervalOptions: IntervalOption[];
  onDateRangeSelect: PropsOf<typeof DatePicker>['onDateRangeSelect'];
  onIntervalChanged: (interval: InsightsInterval) => void;
  onCategorySelect: (categoryId: string) => void;
};

const InsightsSubheader = (props: InsightsHeaderProps) => {
  const {
    dateRange,
    category,
    interval,
    intervalOptions,
    categoryOptions,
    onCategorySelect,
    onDateRangeSelect,
    onIntervalChanged,
  } = props;

  return (
    <SubheaderContainer>
      <SelectorContainer>
        {/* TODO (Milecia): leaving this commented out because we'll likely add this back at some point. */}
        {/* <SelectInputWrapper>
          <SelectInput
            options={anchorMetricOptions}
            size="small"
            value={anchorMetric}
            showExpandIcon={false}
            hasDropdownIcons
            renderValue={() => (
              <IconLabelContainer>
                <Breakdown />
                <Text variant="body2Semibold" color="proximaGreen20">
                  Anchor by: {anchorMetric}
                </Text>
              </IconLabelContainer>
            )}
            onChange={(newAnchorMetric) => onAnchorMetricChanged(newAnchorMetric as InsightsMetric)}
          />
        </SelectInputWrapper>
        <IconLabelContainer>
          <Tooltip title={<TooltipContext />} placement="bottom" arrow info />
          <Text variant="body3Semibold" color="neutral70">
            What is this?
          </Text>
        </IconLabelContainer> */}
      </SelectorContainer>
      <SubheaderDetails>
        <IntervalSelectInputWrapper>
          <SelectInput
            key={category}
            onChange={onCategorySelect}
            options={categoryOptions}
            value={category}
          />
        </IntervalSelectInputWrapper>
        <DatePicker
          dateRange={{
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          }}
          onDateRangeSelect={onDateRangeSelect}
          withRange
          showSelectWithDateRange={false}
          disableFutureDates
        />
        <IntervalSelectInputWrapper>
          <SelectInput
            options={intervalOptions}
            size="small"
            value={interval}
            renderValue={() => (
              <IconLabelContainer>
                <Union />
                <Text variant="body2Semibold" color="neutral90">
                  {interval}
                </Text>
              </IconLabelContainer>
            )}
            onChange={(newInterval) => onIntervalChanged(newInterval as InsightsInterval)}
          />
        </IntervalSelectInputWrapper>
      </SubheaderDetails>
    </SubheaderContainer>
  );
};

export default InsightsSubheader;
